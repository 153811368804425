import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import { Button } from 'reactstrap';
import Complete from '../components/complete';
import completeImage from '../assets/img/complete.png';

class success extends Component {
  state = {
    payvia: '',
  }

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    let trxId = '';
    for (let param of query.entries()) {
      if (param[0] === 'payvia') {
        trxId = param[1];
      }
    }
    this.setState({payvia: trxId});
  }

  render() {
    return (
      <div>
        {/* <Complete payvia={this.state.payvia}></Complete> */}
        <div className="container">
          <div className="app-center complete-w text-center">
            <img className="complete-img" alt="" src={completeImage} ></img>

            <h5>
              {/* {props.payvia === 'ovo' ? 'Setiap pembayaran dengan metode OVO, Harap melanjutkan di Aplikasi OVO bagian Notifikasi anda. Terima Kasih' : 'your order will be serve + 15 minutes from now'} */}
              Setiap pembayaran dengan metode OVO, Harap melanjutkan di Aplikasi OVO bagian Notifikasi anda. Terima Kasih 
            </h5>

            <Link to="/">
              <div className="">
                <Button className="btn btn-danger btn-info w-100" onClick={this.backToPage}> Home </Button>
              </div>
            </Link>  
          </div>
        </div>
      </div>
    )
  }
}

export default success;