import React from 'react';
import Routes from './routes/routes';
import Layout from './hoc/layout/layout';
import './App.css';

function App() {
  return (
    <Layout>
      <Routes></Routes>
    </Layout>
  );
}

export default App;
