import React, {Component} from 'react';
import { listmenuApiPath } from '../../services/networkApi';
import api from '../../services/network';
import {
  Card,
  CardTitle,
  CardBody,
  Button,   
  Row,
  Col,
  CardFooter,
  Table,
} from "reactstrap";
import {
  Accordion,
  Button as BootsButton,
} from 'react-bootstrap'
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import { imageApi } from '../../config';
import Aux from '../../hoc/aux/aux';
import Search from '../../components/ui/search/search';
import ExpendableText from '../../components/ui/expand/expand';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartArrowDown, faEye } from '@fortawesome/free-solid-svg-icons';
import Modal from '../../components/ui/modal/modal'; 
import FloatingButton from '../../components/ui/button/floatingButton/floatingButton';
import NumberFormat from 'react-number-format';
import { filterList, summaryArray } from '../../utils/utility';
import imageDefault from '../../assets/img/okgo.png';
import './listingMenu.css';

class listingMenu extends Component {
  state = {
    filterValue: {
      elementConfig: {
          type: 'text',
          placeholder: 'TRY QUICK SEARCH'
      },
      value: ''
    },
    isOpen: false,
    listingData: [],
    loading: true,
    cartable: false,
    selectedItem: [],
    selectedAddon: [],
  }

  async componentDidMount() {
    if (this.props.merchantId === 'undefined' || this.props.merchantId === '' || this.props.merchantId === null) { 
      this.props.history.push('/'); 
    } else {
      const listingApi = api(listmenuApiPath);
      const payload = {
        merchantId: this.props.merchantId, 
        category:'ALL', 
        preorder: 'false', 
        specific: 'true'
      };
      const listingMenu = await listingApi.post(payload);
      let results = listingMenu.map( el => {
        let cartItem = this.props.cart.filter(function(item) {
          return item.id === el.id;
        });

        let o = Object.assign({}, el);
        o.qtyOrders = summaryArray(cartItem, 'qty')
        o.expandable = false;
        return o;
      })

      this.setState({
        listingData: results
      });
    }
  }

  backToPage = () => {
    this.props.history.goBack();
  }

  cartContinuedHandler = (item) => {
    let carts = [ ...this.props.cart ];

    const cart = carts.filter(items => {
      return items.id === item.id;
    });

    if (cart.length === 0) {
      // const queryParams = [];
      // queryParams.push('listing=' + item.id);
      // queryParams.push('edit=false');
      // queryParams.push('cart=0');
      // const queryString = queryParams.join('&');
      this.props.history.push({
        pathname: '/listing-menu/detail',
        state: {
          listing: item.id,
          edit: false,
          cart: 0
        }
      });
    } else {
      this.setState({
        cartable: true,
        selectedItem: cart,
      });
    }
  }

  cartCanceledHandler = () => {
    this.setState({
      cartable: false,
    });
  }

  gotoCart = () => {  
    this.props.history.push('/listing-cart');
  }

  gotoDetailCart = (listingId, cartId, isEdit) => {
    if (isEdit) {
      this.props.history.push({
        pathname: '/listing-menu/detail',
        state: {
          listing: listingId,
          edit: true,
          cart: cartId
        }
      });
    } else {
      this.props.history.push({
        pathname: '/listing-menu/detail',
        state: {
          listing: listingId,
          edit: false,
          cart: 0
        }
      });
    }
  }

  inputChangedHandler = (event) => {
    const value = event.target.value;
    this.setState({
      filterValue: {
        ...this.state.filterValue,
        value: value
      }
    });
  }

  addDefaultSrc = (ev) => {
    ev.target.src = imageDefault;
  }

  render() {
    let floatButton = null;
    let listing = null;
    let cartModal = null;
    let item = this.state.selectedItem;
    let cartList = null;
    let listingData = filterList(this.state.listingData, this.state.filterValue.value);

    floatButton = this.props.cart.length > 0 ? <FloatingButton clicked={this.gotoCart} cartCount={this.props.cart.length}></FloatingButton> : null;

    cartList = item.map( (el, index) => {
      let listAddOn = null;
      let addOn = null;
      if (typeof el.listingAddOn !== 'undefined' && el.listingAddOn.length > 0) {
        listAddOn =el.listingAddOn.map ((elAdd, indexAdd) => {
          return (
            <li key={indexAdd}> {elAdd.name} </li>
          )
        })

        addOn = (
          <Accordion>
            <Accordion.Toggle as={BootsButton} variant="link" eventKey="0" className="toggle-accordion">
              View AddOn
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <ul>
                {listAddOn}
              </ul>
            </Accordion.Collapse>
          </Accordion> 
        )
      }

      return (
        <Row key={index}>
          <Col>
            {el.name}
          </Col>
          <Col>
            {el.qty}
          </Col>
          <Col> {addOn} </Col>
          <Col>
            <div tag="a" onClick={() => this.gotoDetailCart(el.id, el.cartId, true)} className="float-right"> Edit </div>
          </Col>
        </Row>
      )
    })

    cartModal = Object.keys(item).length > 0 ? (
      <Modal show={this.state.cartable} modalClosed={this.cartCanceledHandler}>
        <div className="disable-selected">
          <Table responsive>
            <thead className="text-center"><tr><th><h4><b>UPDATE ITEM DETAIL</b></h4></th></tr></thead>
            <tbody>
              <tr>
                <td>
                  {cartList}
                </td>
              </tr>
              <tr>
                <td>
                  <div className="row justify-content-between push-up-lg">
                    <div className="col-4">
                      <Button outline color="success" className='w-100' onClick={() => this.gotoDetailCart(item[0].id, 0, false)}> ADD NEW </Button>
                    </div>
                    <div className="col-4">
                      <Button outline color="danger" className='w-100' onClick={this.cartCanceledHandler}> CANCEL </Button>
                      {/* <Button outline color="success" className='w-100' onClick={() => this.updateQty(item)}> OK </Button> */}
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Modal>
    ) : null;

    listing = listingData.map( (el, index) => {
      let listingClasses = ["card", "card-inverse", "listing-card", "text-center", "white" ];
      let indexColor = 1;
      let cartText = el.qtyOrders > 0 ? 'View Cart (' + el.qtyOrders + ')' : 'Add To Cart';
      let cartIcon = el.qtyOrders > 0 ? faEye : faCartArrowDown;
      
      switch(indexColor) {
        case 1: 
          listingClasses.push("col-primary");
          break;
        case 2: 
          listingClasses.push("col-gold");
          break;
        case 3: 
          listingClasses.push("col-danger");
          break;
        case 4: 
          listingClasses.push("col-info");
          break;
        case 5: 
          listingClasses.push("col-success");
          break;
        default:
          listingClasses.push("col-warning");
      }

      return (
        <Col sm="12" md="4" key={el.id}>
          <Card className={listingClasses.join(' ')}>
              <CardBody>
                <div className="row d-flex">
                    <div className="col align-self-center">
                      <img onError={this.addDefaultSrc} className="menu-img" src={imageApi+el.image} alt=""/>
                    </div>
                    <div className="col align-self-center">
                      <CardTitle className="information-header">{el.name}</CardTitle>
                      <div className="p card-text">
                        <ExpendableText expandable={el.expandable} maxHeight={30} textButton={['Shown Less', 'Show More']}>
                          {el.detail}
                        </ExpendableText>
                      </div>
                    </div>
                </div>
              </CardBody>
              <CardFooter>
                <div className="row d-flex">
                  <div className="col align-self-center information-header">
                    <NumberFormat value={el.price} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} />
                  </div>
                  <div className="col align-self-center">
                    <Button className="button-cart btn btn-dark btn-info btn-darken-3" onClick={() => this.cartContinuedHandler(el)}><FontAwesomeIcon icon={cartIcon}></FontAwesomeIcon> {cartText} </Button>
                  </div>
                </div> 
              </CardFooter>
          </Card>
        </Col>
      )
    })

    return (
      <Aux>
        <div className="content">
          {floatButton}
          {cartModal}
          <Search
            elementConfig={this.state.filterValue.elementConfig}
            value={this.state.filterValue.value}
            changed={(event) => this.inputChangedHandler(event)}
            titleBar={this.props.merchantName}
            isBack='true'
            backwardPage={() => this.backToPage()}
          ></Search>    
          <Row className="row-eq-height mt-4">
            {listing}
          </Row>
        </div>
      </Aux>
    )
  }
}

const mapStateToProps = state => {
  return {
      cart: state.cart.items,
      merchantId: state.cart.merchantId,
      merchantName: state.cart.merchantName,
  };
};

const mapDispatchToProps = dispatch => {
  return {
      onStoreCart: (item) => dispatch(actions.addCart(item)),
      onRemoveCart: (item) => dispatch(actions.deleteCart(item)),
      onUpdateCart: (item) => dispatch(actions.updateQtyCart(item)),
      onUpdateAddOnCart: (item) => dispatch(actions.updateAddOnCart(item)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(listingMenu);


// import React, {Component} from 'react';
// import { listmenuApiPath } from '../../services/networkApi';
// import api from '../../services/network';
// import {
//   Card,
//   CardTitle,
//   CardBody,
//   Button,   
//   Row,
//   Col,
//   CardFooter,
//   Table
// } from "reactstrap";
// import { connect } from 'react-redux';
// import * as actions from '../../store/actions/index';
// import { imageApi } from '../../config';
// import Aux from '../../hoc/aux/aux';
// import Search from '../../components/ui/search/search';
// import ExpendableText from '../../components/ui/expand/expand';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCartArrowDown, faMinusSquare, faPlusSquare, faEye } from '@fortawesome/free-solid-svg-icons';
// import Modal from '../../components/ui/modal/modal'; 
// import FloatingButton from '../../components/ui/button/floatingButton/floatingButton';
// import NumberFormat from 'react-number-format';
// import { filterList } from '../../utils/utility';
// import imageDefault from '../../assets/img/okgo.png';
// import './listingMenu.css';

// class listingMenu extends Component {
//   state = {
//     filterValue: {
//       elementConfig: {
//           type: 'text',
//           placeholder: 'TRY QUICK SEARCH'
//       },
//       value: ''
//     },
//     isOpen: false,
//     listingData: [],
//     loading: true,
//     cartable: false,
//     addOnable: false,
//     selectedItem: [],
//     selectedAddon: [],
//     test: 0
//   }

//   async componentDidMount() {
//     if (this.props.merchantId === 'undefined' || this.props.merchantId === '' || this.props.merchantId === null) { 
//       this.props.history.push('/'); 
//     } else {
//       const listingApi = api(listmenuApiPath);
//       const payload = {
//         merchantId: this.props.merchantId, 
//         category:'ALL', 
//         preorder: 'false', 
//         specific: 'true'
//       };
//       const listingMenu = await listingApi.post(payload);
//       let results = listingMenu.map( el => {
//         let o = Object.assign({}, el);
//         o.qtyOrders = 0;
//         o.deliveryFee = 0;
//         o.expandable = false;
//         o.validAddOn = false;
//         return o;
//       })

//       this.setState({
//         listingData: results
//       });
//     }
//   }

//   toggle = () => {
//     this.setState({
//       isOpen: true
//     })
//   };

//   backToPage = () => {
//     this.props.history.goBack();
//   }

//   addOnContinuedHandler = (item) => {
//     let itemData = [];
//     itemData = JSON.parse(JSON.stringify([ ...item.listingAddOn ]));

//     this.setState({
//       addOnable: true,
//       selectedAddon: itemData,
//     }); 
//   }

//   addOnCanceledHandler = () => {
//     this.setState({
//       addOnable: false,
//       selectedAddon: []
//     })
//   }

//   cartContinuedHandler = (item) => {
//     const itemData = Object.assign({}, item);
    
//     this.setState({
//       cartable: true,
//       selectedItem: itemData,
//     });
//   }

//   cartCanceledHandler = () => {
//     this.setState({
//       cartable: false,
//     });
//   }

//   incrementQty = (item) => {
//     item.qtyOrders += 1;
//     this.setState({
//       selectedItem: item
//     })
//   }

//   decrementQty = (item) => {
//     if (item.qtyOrders === 0) { return; }

//     item.qtyOrders -= 1;
//     this.setState({
//       selectedItem: item
//     })
//   }

//   updateAddOn = () => {
//     let selectedAddOn = [...this.state.selectedAddon];
//     let selectedId = selectedAddOn.length > 0 ? selectedAddOn[0].listingId : 0;

//     let updatedListing = [
//       ...this.state.listingData
//     ]

//     const itemList = updatedListing.filter(items => {
//       return items.id === selectedId
//     });

//     if (itemList.length <= 0) {return;}

//     itemList[0].listingAddOn = selectedAddOn;

//     this.setState({
//       addOnable: false,
//       selectedAddOn: [],
//       ...this.state.listingData,
//       ...updatedListing
//     },() => this.props.onUpdateAddOnCart(selectedAddOn)); 
//   }

//   updateQty = (item) => {
//     let updatedListing = [
//       ...this.state.listingData
//     ]

//     const itemList = updatedListing.filter(items => {
//       return items.id === item.id && items.category === item.category;
//     });

//     if (itemList.length <= 0) {return;}
    
//     itemList[0].qtyOrders = item.qtyOrders;
//     itemList[0].validAddOn = false;

//     if (itemList[0].listingAddOn.length > 0  && itemList[0].qtyOrders > 0) { itemList[0].validAddOn = true; }

//     const itemCart = {
//       id: itemList[0].id,
//       name: itemList[0].name,
//       price: itemList[0].price,
//       oldPrice: itemList[0].oldPrice,
//       qty: item.qtyOrders,
//       subtotal: itemList[0].price * item.qtyOrders,
//       category: itemList[0].category,
//       remark: '',
//       merchantId: itemList[0].merchantId,
//       image: itemList[0].image,
//       parentId: itemList[0].parentId,
//       merchantName: itemList[0].merchantName,
//       order: itemList[0].order,
//       supplierId: itemList[0].supplierId,
//       listingAddOn: [],
//     }

//     this.setState({
//       cartable: false,
//       selectedItem: {},
//       ...this.state.listingData,
//       ...updatedListing
//     }, () => this.props.onUpdateCart(itemCart)); 
//   }

//   gotoCart = () => {  
//     this.props.history.push('/listing-cart');
//   }

//   inputChangedHandler = (event) => {
//     const value = event.target.value;
//     this.setState({
//       filterValue: {
//         ...this.state.filterValue,
//         value: value
//       }
//     });
//   }

//   addDefaultSrc = (ev) => {
//     ev.target.src = imageDefault;
//   }

//   handleCheckAddonElement = (event) => {
//     let itemData = Object.assign({}, this.state.selectedAddon);
//     let selectedAddOns = Object.keys(itemData).map((key) =>  itemData[key]);

//     selectedAddOns.forEach(addOn => {
//        if (addOn.id.toString() === event.target.value)
//           addOn.checked =  event.target.checked
//     })

//     this.setState({
//       selectedAddon: selectedAddOns
//     })
//   }

//   render() {
//     let floatButton = null;
//     let listing = null;
//     let cartModal = null;
//     let addOnModal = null;
//     let item = this.state.selectedItem;
//     let addOnSelect = [...this.state.selectedAddon]
//     let listingData = filterList(this.state.listingData, this.state.filterValue.value);

//     floatButton = this.props.cart.length > 0 ? <FloatingButton clicked={this.gotoCart} cartCount={this.props.cart.length}></FloatingButton> : null;

//     addOnModal = <Modal show={this.state.addOnable} modalClosed={this.addOnCanceledHandler}>
//       <div className="disable-selected">
//         <Table responsive>
//         <thead className="text-center"><tr><th><h4><b>ADD ON</b></h4></th></tr></thead>
//           <tbody>
//             <tr>
//               <td>
//               <ul>
//                 {
//                   addOnSelect.map((addOn) => {
//                     return (
//                       <li key={addOn.id}>
//                         <input type="checkbox" onChange={(e) => this.handleCheckAddonElement(e)} checked={addOn.checked} value={addOn.id} /> {addOn.name}  
//                         ( <NumberFormat value={addOn.price} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} /> )
//                       </li>
//                     )
//                   })
//                 }
//               </ul>
//               </td>
//             </tr>
//             <tr>
//                 <td>
//                   <div className="row justify-content-between push-up-lg">
//                     <div className="col-4">
//                       <Button outline color="danger" className='w-100' onClick={this.addOnCanceledHandler}> CANCEL </Button>
//                     </div>
//                     <div className="col-4">
//                       <Button outline color="success" className='w-100' onClick={this.updateAddOn}> OK </Button>
//                     </div>
//                   </div>
//                 </td>
//               </tr>
//           </tbody>
//         </Table>
//       </div>
//     </Modal>

//     cartModal = Object.keys(item).length > 0 ? (
//       <Modal show={this.state.cartable} modalClosed={this.cartCanceledHandler}>
//         <div className="text-center disable-selected">
//           <Table responsive>
//             <thead><tr><th><h4><b>Q T Y</b></h4></th></tr></thead>
//             <tbody>
//               <tr>
//                 <td>
//                   <Row>
//                     <Col>
//                       <div className="col-center" tag="a" onClick={() => this.decrementQty(item)}>
//                         <FontAwesomeIcon icon={faMinusSquare} size="2x" color="black"/> 
//                       </div>
//                     </Col>
//                     <Col>
//                       <div className="col-center"> {item.qtyOrders} </div>
//                     </Col>
//                     <Col>
//                       <div className="col-center" tag="a" onClick={() => this.incrementQty(item)}>
//                         <FontAwesomeIcon icon={faPlusSquare} size="2x" color="black"/> 
//                       </div>
//                     </Col>
//                   </Row>
//                 </td>
//               </tr>
//               <tr>
//                 <td>
//                   <div className="row justify-content-between push-up-lg">
//                     <div className="col-4">
//                       <Button outline color="danger" className='w-100' onClick={this.cartCanceledHandler}> CANCEL </Button>
//                     </div>
//                     <div className="col-4">
//                       <Button outline color="success" className='w-100' onClick={() => this.updateQty(item)}> OK </Button>
//                     </div>
//                   </div>
//                 </td>
//               </tr>
//             </tbody>
//           </Table>
//         </div>
//       </Modal>
//     ) : null;

//     listing = listingData.map( (el, index) => {
//       let listingClasses = ["card", "card-inverse", "listing-card", "text-center", "white" ];
//       // let indexColor = (index + 1) % 6;
//       let indexColor = 1;
//       let cartText = el.qtyOrders > 0 ? 'View Cart (' + el.qtyOrders + ')' : 'Add To Cart';
//       let cartIcon = el.qtyOrders > 0 ? faEye : faCartArrowDown;
//       let buttonAddOn = el.validAddOn ? 
//       (<Button className="button-addon push-up-sm btn btn-dark btn-info btn-darken-3" onClick={() => this.addOnContinuedHandler(el)}><FontAwesomeIcon icon={faPlusSquare}></FontAwesomeIcon> ADDON </Button>) 
//       : null;
      
//       switch(indexColor) {
//         case 1: 
//           listingClasses.push("col-primary");
//           break;
//         case 2: 
//           listingClasses.push("col-gold");
//           break;
//         case 3: 
//           listingClasses.push("col-danger");
//           break;
//         case 4: 
//           listingClasses.push("col-info");
//           break;
//         case 5: 
//           listingClasses.push("col-success");
//           break;
//         default:
//           listingClasses.push("col-warning");
//       }

//       return (
//         <Col sm="12" md="6" key={el.id}>
//           <Card className={listingClasses.join(' ')}>
//               <CardBody>
//                 <div className="row d-flex">
//                     <div className="col align-self-center">
//                       <img onError={this.addDefaultSrc} className="menu-img" src={imageApi+el.image} alt=""/>
//                     </div>
//                     <div className="col align-self-center">
//                       <CardTitle className="information-header">{el.name}</CardTitle>
//                       <div className="p card-text">
//                         <ExpendableText expandable={el.expandable} maxHeight={30} textButton={['Show More', 'Show Less']}>
//                           {el.detail}
//                         </ExpendableText>
//                       </div>
//                     </div>
//                 </div>
//               </CardBody>
//               <CardFooter>
//                 <div className="row d-flex">
//                   <div className="col align-self-center information-header">
//                     <NumberFormat value={el.price} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} />
//                   </div>
//                   <div className="col align-self-center">
//                     <Button className="button-cart btn btn-dark btn-info btn-darken-3" onClick={() => this.cartContinuedHandler(el)}><FontAwesomeIcon icon={cartIcon}></FontAwesomeIcon> {cartText} </Button>
//                     {buttonAddOn}
//                   </div>
//                 </div> 
//               </CardFooter>
//           </Card>
//         </Col>
//       )
//     })

//     return (
//       <Aux>
//         <div className="content">
//           {floatButton}
//           {addOnModal}
//           {cartModal}
//           <Search
//             elementConfig={this.state.filterValue.elementConfig}
//             value={this.state.filterValue.value}
//             changed={(event) => this.inputChangedHandler(event)}
//             titleBar={this.props.merchantName}
//             isBack='true'
//             backwardPage={() => this.backToPage()}
//           ></Search>    
//           <Row className="row-eq-height mt-4">
//             {listing}
//           </Row>
//         </div>
//       </Aux>
//     )
//   }
// }

// const mapStateToProps = state => {
//   return {
//       cart: state.cart.items,
//       merchantId: state.cart.merchantId,
//       merchantName: state.cart.merchantName,
//   };
// };

// const mapDispatchToProps = dispatch => {
//   return {
//       onStoreCart: (item) => dispatch(actions.addCart(item)),
//       onRemoveCart: (item) => dispatch(actions.deleteCart(item)),
//       onUpdateCart: (item) => dispatch(actions.updateQtyCart(item)),
//       onUpdateAddOnCart: (item) => dispatch(actions.updateAddOnCart(item)),
//   };
// };

// export default connect(mapStateToProps, mapDispatchToProps)(listingMenu);