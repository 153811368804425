import axios from 'axios';
import { baseApi } from '../config';

const createNetwork = (baseUrl, isBase = true, headers = '') => {
    let url = baseApi + baseUrl;
    if (!isBase) {
        url = baseUrl;    
        axios.defaults.headers.common['Authorization'] = `Basic ${headers}`;
    }
    
    return {
        // getWithHeader: async(token) => {
        //     const data  = await axios.get(url, {
        //         headers: {
        //           'Authorization': `Basic ${token}`,
        //           'Access-Control-Allow-Origin': '*',
        //         }
        //       });
        //       return data
        // },
        get: async() => {
            const { data } = await axios.get(url);
            return data;
        },
        getWithQuery: async (query) => {
            const { data } = await axios.get(url, query);
            return data;
        },
        getWithParams: async (params) => {
            const { data } = await axios.get(`${url}/${params}`);
            return data;
        },
        post: async (body) => {
            const { data } = await axios.post(url, body);
            return data;
        },
        postWithQuery: async (query) => {
            const { data } = await axios.post(url, query);
            return data;
        },
        postWithParams: async (params) => {
            const { data } = await axios.post(`${url}/${params}`);
            return data;
        },
        put: async (body, params) => {
            const { data } = await axios.put(`${url}/${params}`, body);
            return data;
        },
        delete: async (params) => {
            const { data } = await axios.delete(`${url}/${params}`);
            return data;
        }
    }
}

export default createNetwork;