import axios from 'axios';
import showNotifications from '../components/Toast';

const networkInterceptor = () => {
  axios.interceptors.response.use(null, function(e) {
    if (!e.response) {
      showNotifications('Please check your internet connection!', 'error', 'Connection error')
    }

    // if (e.statusCode === 404) {
    //   showNotifications('Please relogin your current user connection!', 'error', 'Authentication Error')
    // }
  
    // if (e.response.status >= 400 && e.response.status < 500 && e.response.status !== 403) {
    //   showNotifications(prettifyErrorMessage(e.response.data.message), 'warning', 'Fail')
    // }
  
    // if (e.response.status >= 500 && e.response.status < 600) {
    //   showNotifications(e.response.data.message, 'error', 'Internal Server Error')
    // }
  
    return Promise.reject(e);
  })
}

export default networkInterceptor;