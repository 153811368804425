import { createStore, combineReducers, applyMiddleware } from 'redux';
import jwt from 'jsonwebtoken';
import { secretKeyStorage } from '../config';
import userReducer from './reducers/user';
import cartReducer from './reducers/cart';
import thunk from 'redux-thunk';

const hashAlgorithm = (s) => {
  return 'OK-' + s + '-GO';
}

const saveState = (state) => {
  try {
    const todayDate = new Date();
    const { user, cart } = state;
    const crypto = jwt.sign({
      user, cart
    }, secretKeyStorage);
    let randId = '';
    let dd = todayDate.getDate();
		let mm = todayDate.getMonth()+1; 
    let yy = todayDate.getFullYear().toString().substr(-2);
    let time = todayDate.getHours().toString() + todayDate.getMinutes().toString() + todayDate.getMilliseconds().toString();
		if (dd < 10) {
			dd ='0'+dd;
		}
		if (mm < 10){
			mm = '0'+mm;
		} 

    if (localStorage.getItem('stateToken') == null) {
      randId = Math.floor((Math.random() * 100000) + 1).toString();
    } else {
      randId = localStorage.getItem('ticket').toString();
    }

    randId = randId + dd.toString() + mm.toString() + yy.toString() + time;

    localStorage.setItem('ok-data', crypto);
    localStorage.setItem('ok-token', hashAlgorithm(randId));
    localStorage.setItem('ok-ticket', randId);

  } catch (e) {
   console.log(e);
  }
};

const peristedState = jwt.verify(localStorage.getItem('ok-data'), secretKeyStorage, (err, decoded) => {
  
  if (decoded) {
    if (localStorage.getItem('ok-data')) {
      let { user, cart } = decoded;
      if (hashAlgorithm(localStorage.getItem('ok-ticket')).toString() !== localStorage.getItem('ok-token').toString()) {
        localStorage.clear();
        user = [];
        cart = [];
      }
      return { user, cart };
    }
    return undefined;
  }
  localStorage.clear();
})

const rootReducer = combineReducers({
  user: userReducer,
  cart: cartReducer
});

const store = createStore(rootReducer, peristedState, applyMiddleware(thunk));

store.subscribe(() => {
saveState(store.getState());
});

export default store;

