import React, {Component} from 'react';
import './modal.css';
import Aux from '../../../hoc/aux/aux';
import Backdrop from '../backdrop/backdrop';

class modal extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.show !== this.props.show || nextProps.children !== this.props.children;
  }

  render () {
    return (
      <Aux>
        <Backdrop show={this.props.show} clicked={this.props.modalClosed}></Backdrop>
        <div className={this.props.show ? 'Modal Modal-Open' : 'Modal Modal-Close'}
          style={{
            transform: this.props.show ? 'translateY(0)' : 'translateY(-500vh)',
            opacity: this.props.show ? '1' : '0'
          }}>
          {this.props.children}
        </div>
      </Aux>
    )
  }
} 

export default modal;