import React from 'react';
import './toolbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

// import Switcher from '../../../components/UI/Switcher/Switcher';
import { useLocation } from 'react-router-dom';


const toolbar = (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  let location = useLocation();  
  let toolbarSwitch = null;
  if (location.pathname !== '/') {
    toolbarSwitch = (<header className="Toolbar">
    <div tag="a"  onClick={props.drawerToggleClicked}>
      <FontAwesomeIcon icon={faBars} size="lg"></FontAwesomeIcon> {props.cartCount}
    </div>
  </header>)
  }

  return (
    // <header className="Toolbar">
    //   <div tag="a"  onClick={props.drawerToggleClicked}>
    //     <FontAwesomeIcon icon={faBars} size="lg"></FontAwesomeIcon> {props.cartCount}
    //   </div>
    // </header>
    <div>
      {toolbarSwitch}
    </div>
    
  );
  
}

export default toolbar;