import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store/store';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import history from './utils/history';
import { Router } from 'react-router-dom';
import NetworkInterceptor from './services/networkInterceptor';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

<link href="//vjs.zencdn.net/5.4.6/video-js.min.css" rel="stylesheet"></link>
const app = (
  <Provider store={store}>
    <Router history={history}>
      <ToastContainer />
      <App></App>
    </Router>
  </Provider>
)

NetworkInterceptor();

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
