import React from 'react';
import { NavLink } from 'react-router-dom';
import './navigationItem.css';

const navigationItem = (props) => (
  <div className="SidebarMenu">
    <li className="NavigationItem">
      <NavLink
        className="SidebarNav"
        onClick={props.clicked} 
        to={props.link}
        exact={props.exact}
        activeClassName="active">
        <div className="sides"></div>
        {props.children}</NavLink>
    </li>
  </div>
  
);

export default navigationItem;
