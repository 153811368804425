import { updateObject } from '../../utils/utility';
import * as actionTypes from '../actions/actionTypes';

const initState = {
  isLoggedIn: false,
  userId: '',
  fullName: null,
  parentId: null,
  token: null,
  error: null,
  loading: false,
  creator: '',
}

const authStart = (state, action) => {
  return updateObject( state, { error: null, loading: false } );
}

const authFail = (state, action) => {
  return updateObject( state, { error: action.error, loading: false } );
}

const authSuccess = (state, action) => {
  const data = {
      isLoggedIn:true,
      userId: action.userInfo.userId,
      fullName: action.userInfo.fullName,
      parentId: action.userInfo.parentId,
      token: action.userInfo.token,
      error: null,
      loading: false,
      creator: action.userInfo.creator
  };
  return updateObject(state, data);
}

const removeAuth = (state, action) => {
  const data = {
      isLoggedIn:false,
      userId: '',
      fullName: null,
      parentId: null,
      token: null,
      error: null,
      loading: false,
      creator: null,
  }
  return updateObject(state, data);
}

const reducer = ( state = initState, action ) => {
  switch ( action.type ) {
      case actionTypes.AUTHENTICATION_START: return authStart(state, action);
      case actionTypes.AUTHENTICATION_FAIL: return authFail(state, action);
      case actionTypes.SET_AUTHENTICATION: return authSuccess(state, action);
      case actionTypes.REMOVE_AUTHENTICATION: return removeAuth(state, action);
      default:
  }
  return state;
};

export default reducer;