import { toast } from 'react-toastify';
import React from 'react';

const showNotifications = (msg, type, title) => {
  const message = () => (
    <div className="auth-string">
      {msg}
    </div>
  )
  
  switch (type) {
    case 'info':
      toast.info(message, {
        position: toast.POSITION.TOP_RIGHT
      });
      break
    case 'success':
      toast.success(message, {
        position: toast.POSITION.TOP_RIGHT
      });
      break
    case 'warning':
      toast.warn(message, {
        position: toast.POSITION.TOP_RIGHT
      });
      break
    case 'error':
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT
      });
      break
    default:
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT
      });
      break
  }
}

export default showNotifications;
  