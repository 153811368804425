import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons';
import './search.css';

const search = (props) => {
  let searchClasses = ["search-element"];
  searchClasses = [...searchClasses, props.className];

  const search = props.isBack === 'true' ? (
    <Row className="search-bar">
      <Col md={5}>
        <Row>
          <Col md={10} xs={10}>
            <input {...props.elementConfig} value={props.value}
              onChange={props.changed} className={searchClasses.join(' ')}></input>
          </Col>
          <Col md={2} xs={2} className="logo-search">
            <FontAwesomeIcon icon={faSearch} size="lg"></FontAwesomeIcon> 
          </Col>
        </Row>
      </Col>

      <Col md={6}>
        <div className="float-right information-header black">
          {props.titleBar}
        </div>
      </Col>

      <Col md={1}>
        <div tag="a" onClick={props.backwardPage} className="float-right information-header black">
          <FontAwesomeIcon icon={faArrowAltCircleLeft} size="2x"></FontAwesomeIcon>
        </div>
      </Col>
    </Row>
  ) : ( 
    <Row className="search-bar">
      <Col md={5}>
        <Row>
          <Col md={10} xs={10}>
            <input {...props.elementConfig} value={props.value}
              onChange={props.changed} className={searchClasses.join(' ')}></input>
          </Col>
          <Col md={2} xs={2} className="logo-search">
            <FontAwesomeIcon icon={faSearch} size="lg"></FontAwesomeIcon> 
          </Col>
        </Row>
      </Col>

      <Col md={7}>
        <div className="float-right information-header black">
          {props.titleBar}
        </div>
      </Col>
    </Row> 
  )
  
  return search;
}

export default search;