//FOR USERS
export const AUTHENTICATION_START = 'AUTHENTICATION_START';
export const AUTHENTICATION_FAIL = 'AUTHENTICATION_FAIL';
export const SET_AUTHENTICATION = 'SET_AUTHENTICATION';
export const REMOVE_AUTHENTICATION = 'REMOVE AUTHENTICATION';

//FOR CARTS
export const SET_MERCHANT = 'SET_MERCHANT';
export const ADD_CART = 'ADD_CART';
export const DEL_CART = 'DEL_CART';
export const CLEAR_CART = 'CLEAR_CART';
export const UPDATE_QTY_CART = 'UPDATE_QTY_CART';
export const UPDATE_CART = 'UPDATE_CART';
export const UPDATE_ADDON_CART = 'UPDATE_ADDON_CART';