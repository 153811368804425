import React from 'react';
import './floatingButton.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartArrowDown } from '@fortawesome/free-solid-svg-icons'

const floatingButton = (props) => {
  return (
    <React.Fragment>
      <span className="fa-stack fa-5x has-badge">
        <span className="fa-stack-text">
          {props.cartCount}
        </span>
      </span>
      <div tag="a" className="float front" onClick={props.clicked}>
        <div className="my-float">
          <FontAwesomeIcon icon={faCartArrowDown} size="1x"></FontAwesomeIcon>
        </div>
      </div>
    </React.Fragment>
    
  )
  
};

export default floatingButton;