import React, { useRef, useEffect, useState  } from 'react';
import './expand.css'

const ExpendableText = ({ maxHeight, children, expandable, textButton }) => {
  const ref = useRef();
  const [shouldShowExpand, setShouldShowExpand] = useState(false);
  const [expanded, setExpanded] = useState(true);
  const MAX_POSSIBLE_HEIGHT = 500;


  useEffect(() => {
    if (ref.current.scrollHeight > maxHeight) {
      setShouldShowExpand(true);
      setExpanded(false);
    }
  }, [maxHeight]);


  return (
    // <Card.Text ref={ref}>
    //   <p
    //     className="inner-expand"
    //     style={{ maxHeight: expanded ? MAX_POSSIBLE_HEIGHT : maxHeight }} >
    //     {children}
    //   </p>
    //   {shouldShowExpand && (
    //     <button className="round information-xsmall" onClick={() => setExpanded(!expanded)}>{ expanded ? 'Show Less' : 'Show More' }</button>
    //   )}
    // </Card.Text>

    <div className="p card-text" ref={ref}>
      <div
        className="inner-expand"
        style={{ maxHeight: expanded ? MAX_POSSIBLE_HEIGHT : maxHeight }} >
        {children}
      </div>
      {shouldShowExpand && (
        // <button className="round information-xsmall" onClick={() => setExpanded(!expanded)}>{ expanded ? 'Show Less' : 'Show More' }</button>
        <button className="round information-xsmall" onClick={() => setExpanded(!expanded)}>{ expanded ? textButton[0] : textButton[1] }</button>
      )}
    </div>
  );
};

export default ExpendableText;