// import external modules
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Input from '../../../components/ui/input/input';
import { checkValidity, updateObject } from '../../../utils/utility';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';
import Spinner from '../../../components/ui/Spinner/Spinner';
import {
   Row,
   Col,
   Form,
   FormGroup,
   Button,
   Card,   
   CardBody,
   CardFooter
} from "reactstrap";
import './login.css';

class login extends Component {
  state = {
    controls: [
      {
         id: 'email',
         elementType: 'input',
         elementConfig: {
         type: 'email',
         placeholder: 'Mail Address'
         },
         value: '',
         validation: {
         required: true,
         isEmail: true
         },
         valid: false,
         touched: false
      },
      {
         id: 'password',
         elementType: 'input',
         elementConfig: {
            type: 'password',
            placeholder: 'Password'
         },
         value: '',
         validation: {
            required: true,
            minLength: 6
         },
         valid: false,
         touched: false
      }
    ],
    formIsValid: false,
  }


  inputChangedHandler = ( event, controlName ) => {
      let updatedControls = [...this.state.controls];
      let index = updatedControls.findIndex(x => x.id === controlName);

      const updatedControlslements = { 
         ...updatedControls[index]
      };

      updatedControlslements.value = event.target.value;
      updatedControlslements.valid = checkValidity( event.target.value, updatedControlslements.validation );
      updatedControlslements.touched = true;

      updatedControls[index] = updateObject(updatedControls[index], updatedControlslements);

      let formIsValid = true;

      updatedControls.map( formElement => (
      formIsValid = formElement.valid && formIsValid
      ))

      this.setState( { controls: updatedControls, formIsValid: formIsValid } );
   }

   submitHandler = ( event ) => {
      event.preventDefault();
      this.props.onAuth( this.state.controls[0].value, this.state.controls[1].value, this.props.browserId);
    }

   render() {
      const formElementsArray = this.state.controls;
      let result = null;

      if (this.props.id === '') {
         let form = formElementsArray.map( formElement => (
            <FormGroup key={formElement.id}>
               <Col md="12">
                  <Input
                     elementType={formElement.elementType}
                     elementConfig={formElement.elementConfig}
                     value={formElement.value}
                     invalid={!formElement.valid}
                     shouldValidate={formElement.validation}
                     touched={formElement.touched}
                     changed={( event ) => this.inputChangedHandler( event, formElement.id )} 
                     isForms='true'
                  />
               </Col>
            </FormGroup>
         ));
   
         if (this.props.loading) {
            form = <Spinner />
         }

         result = (
            <div className="app-center">
               <Row className="full-height-vh">
                  <Col xs="12" className="d-flex align-items-center justify-content-center">
                     <Card className="gradient-indigo-purple text-center login-body">
                        <CardBody>
                           <h2 className="white py-4 push-bottom-lg">Login</h2>
                           <Form onSubmit={this.submitHandler} className="pt-2"> 
                              {form}
                              <FormGroup className="push-up-lg">
                                 <Col md="12">
                                    <Button type="submit" color="danger" block className="btn-pink btn-raised push-up-lg" disabled={!this.state.formIsValid}>
                                       Submit
                                    </Button>
                                 </Col>
                              </FormGroup>
                           </Form>
                        </CardBody>
                        <CardFooter>
                           <div className="float-left">
                              <NavLink to="/pages/forgot-password" className="text-white">
                                 Forgot Password?
                              </NavLink>
                           </div>
                           <div className="float-right">
                              <NavLink to="/pages/register" className="text-white">
                                 Register Now
                              </NavLink>
                           </div>
                        </CardFooter>
                     </Card>
                  </Col>
               </Row>
            </div>
         );
      } else {
         this.props.history.goBack();
      }

      return result;
   }
}

const mapDispatchToProps = dispatch => {
   return {
       onAuth: (email, password) => dispatch( actions.auth(email, password)),
   };
 };

 const mapStateToProps = state => {
   return {
       loading: state.user.loading,
       error: state.user.error,
       id: state.user.userId,
   };
 };
 
 export default connect(mapStateToProps, mapDispatchToProps )( login );
