import * as actionTypes from './actionTypes';

export const addCart = ( item ) => {
  return {
      type: actionTypes.ADD_CART,
      item: item
  };
};

export const updateQtyCart = ( item ) => {
  return {
    type: actionTypes.UPDATE_QTY_CART,
    item: item
  }
} 

export const updateCart = ( item ) => {
  return {
      type: actionTypes.UPDATE_CART,
      item: item
  };
};

export const deleteCart = ( item ) => {
  return {
      type: actionTypes.DEL_CART,
      item: item
  };
};

export const clearCart = ( item ) => {
  return {
      type: actionTypes.CLEAR_CART,
  };
};

export const setMerchant = (merchant, merchantName) => {
  const merchantInfo = {
    merchantId: merchant,
    merchantName: merchantName,
  }
  return {
    type: actionTypes.SET_MERCHANT,
    merchant: merchantInfo
  }
}

export const updateAddOnCart = ( itemAddOn ) => {
  return {
    type: actionTypes.UPDATE_ADDON_CART,
    listingAddOn: itemAddOn
  }
}

