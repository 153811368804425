import React from 'react';
import home from '../containers/home/home';
import homeScreen from '../containers/homeScreen/homeScreen';
import homeScreen2 from '../containers/homeScreen/homeScreen2';
import login from '../containers/users/login/login';
import listingMenu from '../containers/listingMenu/listingMenu';
import listingMenuDetail from '../containers/listingMenu/listingMenuDetail';
import listingCart from '../containers/listingCart/listingCart';
import Success from '../containers/success';
import { Route, Switch } from 'react-router-dom';

const routes = () => (
  <Switch>
    <Route path='/' exact component={homeScreen2}></Route>
    <Route path='/home' exact component={home}></Route>
    <Route path='/login' component={login}></Route>
    <Route path='/listing-menu' exact component={listingMenu}></Route>
    <Route path='/listing-menu/detail' exact component={listingMenuDetail}></Route>
    <Route path='/listing-cart' component={listingCart}></Route>
    <Route path='/payment/success' component={Success}></Route>
  </Switch>
)

export default routes;