import React, {Component} from 'react';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import api from '../../services/network';
import { merchantFeeInfoApiPath } from '../../services/networkApi';
import * as actions from '../../store/actions/index';
import showNotifications from '../../components/Toast';
import Cart from '../../components/cart/cart';
import Select from 'react-select';
import { checkValidity, updateObject, summaryArray } from '../../utils/utility';
import Input from '../../components/ui/input/input';
import Modal from '../../components/ui/modal/modal'; 
import { Button } from 'reactstrap';
import NumberFormat from 'react-number-format';
import { kioskCheckoutPath } from '../../services/networkApi';
import './listingCart.css';

const options = [
  { value: 'DEPAN', label: 'DEPAN' },
  { value: 'TENGAH', label: 'TENGAH' },
  { value: 'BELAKANG', label: 'BELAKANG' },
  { value: 'TAKE AWAY', label: 'TAKE AWAY' }
];

const customStyles = {
  option: provided => ({
    ...provided,
    color: 'black',
    fontSize: '1rem',
  }),
  control: provided => ({
    ...provided,
    color: 'black',
    fontSize: '1rem'
  }),
  singleValue: provided => ({
    ...provided,
    color: 'black',
    fontSize: '1rem'
  })
}

class listingCart extends Component {
  state = {
    controls: [
      {
        id: 'phone',
        name: 'Telephone',
        elementType: 'input',
        elementConfig: {
            type: 'text',
            placeholder: 'Phone',
        },
        value: '0808080080',
        validation: {
            required: true,
            minLength: 9,
            maxLength: 20,
            isNumeric: true
        },
        valid: false,
        touched: false
      },
      {
        id: 'name',
        elementType: 'input',
        elementConfig: {
            type: 'text',
            placeholder: 'Name'
        },
        value: 'erik',
        validation: {
            required: true
        },
        valid: false,
        touched: false
      },
    ],
    formIsValid: false,
    selectedOption: 'DEPAN',
    merchantFee: {
      taxValue: 0,
      serviceTaxValue : 0,
      discountValue: 0
    },
    showRemark: false,
    remarkValue: {
      id: '',
      elementType: 'text-area',
      elementConfig: {
          type: 'text',
          placeholder: 'Add your remark here'
      },
      value: ''
    },
    isCash: false,
    isOvo: true
  }

  async componentDidMount() {
    const merchantFeeApi = api(merchantFeeInfoApiPath);

    let payload = {
      merchantId: this.props.merchantId,
    }

    const merchantFeeRes = await merchantFeeApi.post(payload);
    if (merchantFeeRes) {
      const { errors } = merchantFeeRes;
      if (errors) {
        showNotifications(errors[0].message, 'error', 'Gagal memuat informasi merchant, silakan hubungi pihak okgo');
      } else {
        this.setState({ merchantFee: {
          taxValue: merchantFeeRes.tax,
          serviceTaxValue: merchantFeeRes.serviceTax,
          discountValue: merchantFeeRes.discount,
        }})
      }
    }
  }

  startPaymentHandler = async() => {
    if (this.state.controls[0].value === '') { return; }

    let orderSuccess = false;
    let invoice = '';
    const baseApiCheckout = kioskCheckoutPath;
    const checkoutApi = api(baseApiCheckout);
    const users = {
      phone: this.state.controls[0].value,
      userName: this.state.controls[1].value
    } 
    let carts = JSON.stringify(this.props.cart);

    let payload = {
      order: carts.toString(),      
      payment: this.state.isOvo === true ? 'OVO' : 'CASH',
      tableNo: this.state.selectedOption,
      token: this.props.token,
      users: users
    }

    const checkoutRes = await checkoutApi.post(payload);

    if (checkoutRes) { 
      const { errors } = checkoutRes;
      if (errors) {
        if(errors[0].message !== '') {
          orderSuccess = false;
          showNotifications(errors[0].message, 'error', 'Checkout error');
        } else {
          invoice = checkoutRes.invoice;
          orderSuccess = true;
        }
      } 
    }

    if(orderSuccess === true) {
      showNotifications('Terima kasih telah menggunakan OkGo', 'success', 'Checkout');
      this.props.onRefreshCart();
      this.props.history.push('/payment/success');
    }
  }

  remarkCancelHandler = () => {
    this.setState({
      showRemark: false,
      remarkValue: {
        ...this.state.remarkValue,
        value: '',
        id: ''
      }
    });
  }

  remarkActivated = (el) => {
    this.setState({
      showRemark: true,
      remarkValue: {
        ...this.state.remarkValue,
        value: el.remark,
        id: el.id
      }
    });
  }

  remarkChangedHandler = (event) => {
    const value = event.target.value;
    this.setState({
      remarkValue: {
        ...this.state.remarkValue,
        value: value
      }
    });
  }

  remarkSaveHandler = () => {
    let data = [ ...this.props.cart ];
    const updatedCart = data.filter(items => {
      return items.id === this.state.remarkValue.id;
    })
    
    if (updatedCart.length > 0) {
      updatedCart[0].remark = this.state.remarkValue.value;
    }
    this.props.onUpdateCart(data);

    this.setState({
      showRemark: false,
      remarkValue: {
        ...this.state.remarkValue,
        value: '',
        id: ''
      }
    });
  }

  incrementQty = (item) => {
    this.props.onStoreCart(item);
  }

  decrementQty = (item) => {
    this.props.onRemoveCart(item);
  }

  inputChangedHandler = ( event, controlName ) => {
    let updatedControls = [...this.state.controls];
    let index = updatedControls.findIndex(x => x.id === controlName);

    const updatedControlslements = { 
       ...updatedControls[index]
    };

    updatedControlslements.value = event.target.value;
    updatedControlslements.valid = checkValidity( event.target.value, updatedControlslements.validation );
    updatedControlslements.touched = true;

    updatedControls[index] = updateObject(updatedControls[index], updatedControlslements);

    let formIsValid = true;

    updatedControls.map( formElement => (
    formIsValid = formElement.valid && formIsValid
    ))

    this.setState( { controls: updatedControls, formIsValid: formIsValid } );
 }

  backToPage = () => {
    this.props.history.goBack();
  }

  payTypeChange = (idx) => {
    if (idx === 0) {
      this.setState({
        isCash: false,
        isOvo: true
      })
    } else {
      this.setState({
        isCash: true,
        isOvo: false
      })
    }
  }

  tableChangeHandler = (e) => {
    this.setState({
      selectedOption: e.value
    })
  }

  render() {
    const formElementsArray = this.state.controls;
    let form = formElementsArray.map( formElement => (
      <Input
          key={formElement.id}
          elementType={formElement.elementType}
          elementConfig={formElement.elementConfig}
          value={formElement.value}
          invalid={!formElement.valid}
          shouldValidate={formElement.validation}
          touched={formElement.touched}
          changed={( event ) => this.inputChangedHandler( event, formElement.id )} 
          isForms='true'
          />
    ));    


    let subTotal = this.props.cart.reduce( (sum, el) => {
      let sumAddOn = summaryArray(el.listingAddOn, 'price');
      return sum + (el.qty * el.price) + sumAddOn;
    }, 0);
    const { selectedOption } = this.state.selectedOption;
    const totalDiscount = subTotal * parseInt(this.state.merchantFee.discountValue) / 100;
    const totalPrice = subTotal - totalDiscount;
    const totalTax = totalPrice * parseInt(this.state.merchantFee.taxValue) / 100;
    const totalServiceTax = totalPrice * parseInt(this.state.merchantFee.serviceTaxValue) / 100;
    const netto = totalPrice + totalTax + totalServiceTax;

    let listing = null;

    listing = this.props.cart.map( el => (
      <Cart key={el.cartId} menuName={el.name} 
        menuPrice={el.price} 
        merchant={el.merchantName} 
        menuImage={el.image} 
        menuQty={el.qty}
        menuRemark={el.remark}
        incQty={() => {this.incrementQty(el)}}
        decQty={() => {this.decrementQty(el)}}
        addRemark={() => {this.remarkActivated(el)}}
        ></Cart>
    ));

    let remarkBox = null;
    let remark = null;

    remarkBox = <Input 
                      elementType={this.state.remarkValue.elementType}
                      elementConfig={this.state.remarkValue.elementConfig}
                      value={this.state.remarkValue.value}
                      isForms='false'
                      changed={(event) => this.remarkChangedHandler(event)} />
    
    remark = (
      <div className="justify-content-center">
        <div className="text-center disable-selected information-header">
          REMARK
        </div>
        <div>
          {remarkBox}
          <div className = "d-flex justify-content-center push-up">
            <div className="row w-100 justify-content-between push-up-lg">
              <div className="col-6">
                <Button outline color="success" className='w-100' onClick={this.remarkSaveHandler}>Save</Button>
              </div>
              <div className="col-6">
                <Button outline color="danger" className='w-100' onClick={this.remarkCancelHandler}>Cancel</Button>
              </div>
            </div>
          </div>          
        </div>
      </div>
    )

    return (
      <div className="content">
        <Modal show={this.state.showRemark} modalClosed={this.remarkCancelHandler}>
          {remark}
        </Modal>
        <div className="cart-container">
          <Row>
            <Col md={6} className="cart-list-container">
              <div className="cart-list-content">
                <div className="information-header">
                  <div className="push-bottom-lg">SHOPPING CART</div>
                  {listing}
                </div>
              </div>
            </Col>
            <Col md={3}>
              <div className="cart-checkout-container">
                <div className="checkout-info"> 
                  <div className="information-header push-bottom-lg">Total Transaction </div>
                  
                  <div>Sub Total</div>
                  <div className="card-border-bottom">
                    <NumberFormat value={subTotal} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} />
                  </div>
                  <div className="information-header">Tax</div>
                  <div className="card-border-bottom">
                    <NumberFormat value={totalTax} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} />
                  </div>
                  <div className="information-header">Service Tax</div>
                  <div className="card-border-bottom">
                    <NumberFormat value={totalServiceTax} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} />
                  </div>
                  <div className="information-header">Total</div>
                  <div className="card-border-bottom">
                    <NumberFormat value={netto} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} />
                  </div>
                </div>
              </div>
            </Col>
            <Col md={3}>
              <div className="cart-checkout-container">
                <div className="checkout-info"> 
                  <div className="information-header push-bottom-lg">User Information</div>
                  <div>Payment Type</div>
                  <div className="card-border-bottom">
                      <input
                        className="push-right-sm"
                        checked={this.state.isOvo === true}
                        type="radio"
                        value="OVO"
                        onChange={() => this.payTypeChange(0)}
                      />
                      <p className="push-right-sm paragraph-row"> OVO </p>
                      <input
                        className="push-right-sm push-left-sm"
                        checked={this.state.isCash === true}
                        type="radio"
                        value="CASH"
                        onChange={() => this.payTypeChange(1)}
                      />
                      CASH
                  </div>
                  <div className="information-header">User Information</div>
                  <div className="push-bottom">
                    {form}
                  </div>
                  <div className="information-header">Table</div>
                  <div>
                    <Select
                      value={selectedOption}
                      onChange={this.tableChangeHandler}
                      options={options}
                      styles={customStyles}
                    />
                  </div>
                  <div className="d-flex justify-content-center push-up-md">
                    <Button className="btn btn-danger btn-info btn-darken-3 w-100" onClick={this.startPaymentHandler}> CHECKOUT </Button>
                  </div>
                  <div className="d-flex justify-content-center push-up">
                    <Button className="btn btn-danger btn-info btn-darken-3 w-100" onClick={this.backToPage}> BACK TO MENU </Button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
      cart: state.cart.items,
      creator: state.cart.creator,
      cartType: state.cart.cartType,
      isLogin: state.user.isLoggedIn,
      merchantId: state.cart.merchantId,
      token: state.user.token,
  };
};

const mapDispatchToProps = dispatch => {
  return {
      onStoreCart: (item) => dispatch(actions.addCart(item)),
      onRemoveCart: (item) => dispatch(actions.deleteCart(item)),
      onUpdateCart: (item) => dispatch(actions.updateCart(item)),
      onRefreshCart: () => dispatch(actions.clearCart())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(listingCart);
