import React from 'react';
import './Spinner.css';

const spinner = (props) => {
    let classes = ["Loader"]

    if (props.isOpacity) {
        classes.push("spinner-opacity");
    }

    return (
        <div className={classes.join(' ')}>Loading...</div>
    )
}

export default spinner;