import React from 'react';
import './input.css';

const input = (props) => {
  let inputElement = null;
  let inputLabel = <label className="Label">{props.label}</label>
  let inputClasses = ["InputElement"];

  if (props.invalid && props.shouldValidate && props.touched) {
      inputClasses.push("Invalid");
  }

  inputClasses = [...inputClasses, props.className];

  switch ( props.elementType ) {
    case ( 'input-filter' ):
      inputLabel = null;
      inputElement = <input
                className={inputClasses.join(' ')}
                {...props.elementConfig}
                value={props.value}
                onChange={props.changed} />;
      break;
    
    case ( 'text-area' ):
      inputElement = <textarea
            rows="5"
            className={inputClasses.join(' ')}
            {...props.elementConfig}
            value={props.value}
            onChange={props.changed} />;
      break;

    default:
          inputElement = <input
              className={inputClasses.join(' ')}
              {...props.elementConfig}
              value={props.value}
              onChange={props.changed} />;
  }

  return (
    <div className={props.isForm === false ? "Input" : "input-form Input"}>
      {inputLabel}
      {inputElement}
    </div>
);
}

export default input;