import React, {Component} from 'react';
import Aux from '../../hoc/aux/aux';
import { connect } from 'react-redux';
import { merchantApiPath } from '../../services/networkApi';
import api from '../../services/network';
import { imageApi } from '../../config';
import {
  Card,
  CardTitle,
  CardText,
  CardBody,
  Button,   
  Row,
  Col
} from "reactstrap";
import Search from '../../components/ui/search/search';
import * as actions from '../../store/actions/index';
import './home.css';

class Home extends Component {
  state = {
    filterValue: {
      elementConfig: {
          type: 'text',
          placeholder: 'TRY QUICK SEARCH'
      },
      value: ''
    },
    merchantData: [],
    title: 'LIST OF MERCHANT',
    loading: true,
  }

  async componentDidMount() {
    if (this.props.isLoggedIn !== true || this.props.parentId === null) {
      this.props.history.push('/login');
    } 

    if (this.props.creator !== 'MERCHANT') {
      // this.props.history.push('/home');
      const merchantListApi = api(merchantApiPath)
      const payload = {parentId:  this.props.parentId}
      const merchantList = await merchantListApi.post(payload);

      this.setState({
        merchantData: merchantList,
        loading: false,
      });
    }
  }

  inputChangedHandler = (event) => {
    const value = event.target.value;
    this.setState({
      filterValue: {
        ...this.state.filterValue,
        value: value
      }
    });
  }

  filterList = () => {
    const val = this.state.filterValue.value;
    let filteredList = [
      ...this.state.merchantData
    ]

    filteredList = filteredList.filter(function(item) {
      return item.name.toLowerCase().indexOf(val.toLowerCase()) > -1;
    })

    return filteredList;
  }

  clicked = (el) => {
    if(el.opened) {
      this.props.onSetMerchant(el.id, el.name);
      this.props.history.push('/listing-menu');
    }
  }

  render() {
    const list = this.filterList();
    let listing = null;

    listing = list.map( el => (
      <Col sm="12" md="6" key={el.id}>
        <Card className="card-outline-success listing-card box-shadow-0 text-center p-3">
            <CardBody>
              <div className="row d-flex" tag='a' onClick={() => {this.clicked(el)}}>
                  <div className="col align-self-center">
                    <img src={imageApi+el.logo} className="merchant-img" alt="Card cap 07"/>
                  </div>
                  <div className="col align-self-center">
                    <CardTitle className="mt-3"> <b>{el.name}</b></CardTitle>
                    <CardText>Select merchant to order menu</CardText>
                    <Button className="btn btn-raised btn-info">SELECT HERE</Button>
                  </div>
              </div>
            </CardBody>
        </Card>
      </Col>
    ))

    return(
      <Aux> 
        <div className="content">
          <Search
            elementConfig={this.state.filterValue.elementConfig}
            value={this.state.filterValue.value}
            changed={(event) => this.inputChangedHandler(event)}
            titleBar={this.state.title}
          ></Search>    
          <div>
            <Row>
              {listing}
            </Row>
          </div>
        </div>
      </Aux>
    )
  }
}

const mapStateToProps = state => {
  return {
      isLoggedIn: state.user.isLoggedIn,
      parentId: state.user.parentId,
      fullName: state.user.fullName,
      creator: state.user.creator,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSetMerchant: (merchant, name) => dispatch(actions.setMerchant(merchant, name))
  };
};

export default connect(mapStateToProps, mapDispatchToProps )( Home );


