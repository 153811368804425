import React, {Component} from 'react';
import Toolbar from '../../components/navigation/toolbar/toolbar';
import SideDrawer from '../../components/navigation/sideDrawer/sideDrawer';
import './layout.css';

class layout extends Component {
  state = {
    showSideDrawer: false,
    switch: true,
  }

  switchHandler = () => {
    this.setState( ( prevState ) => {
      return { switch: !prevState.switch };
    } );
  }

  sideDrawerClosedHandler = () => {
    this.setState( { showSideDrawer: false } );
  }

  sideDrawerToggleHandler = () => {
    this.setState( ( prevState ) => {
      return { showSideDrawer: !prevState.showSideDrawer };
    } );
  }

  render() {
    return (
      <React.Fragment>
        <Toolbar switchState={this.state.switch} switchClicked={this.switchHandler} drawerToggleClicked={this.sideDrawerToggleHandler}></Toolbar>
        <SideDrawer open={this.state.showSideDrawer} closed={this.sideDrawerClosedHandler}></SideDrawer>
        <main>
          {this.props.children}
        </main>
      </React.Fragment>
    )
  }
}

export default layout;