import React from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap';
import { imageApi } from '../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import imageDefault from '../../assets/img/okgo.png';
import NumberFormat from 'react-number-format';
import './cart.css';

const Cart = (props) => {
  const remarkNotes = props.menuRemark === '' ? 'Make Notes' : 'Update Notes';
  const addDefaultSrc = (ev) => {
    ev.target.src = imageDefault;
  }

  return (
    <Card className="card-border-bottom col-transparent cart-card">
        <Row noGutters={true}>
          <Col xs={43} md={3}>
            <div className="cart-list-img col-center">
              <img onError={addDefaultSrc} src={imageApi+props.menuImage} alt=""/>
            </div>
          </Col>
          <Col xs={9} md={9}>
            <div className="cart-list-body">
              <div className="information">{props.menuName}</div>
              <div className="information-small gray">{props.merchant}</div>
              <div className="information-small gray">
                {/* IDR {props.menuPrice/1000} */}
                <NumberFormat value={props.menuPrice} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} />
              </div>
              <div className="information-small push-bottom">
                <div tag="a" onClick={props.addRemark}>
                  {/* <RoundedBottom border='1px solid' color="black" width="70%" height="auto" className="no-color">{remarkNotes}</RoundedBottom> */}
                  <Button className="button-cart btn btn-dark btn-info btn-darken-3"> {remarkNotes} </Button>
                </div>
              </div>
              <div className="push-up">
                <div className="float-left push-right" tag="a" onClick={props.decQty}>
                  <FontAwesomeIcon icon={faMinusCircle} size="lg" color="#32394D"/> 
                </div>

                <div className="push-left float-left push-right information">  {props.menuQty} </div>
                                
                <div className="float-left push-left" tag="a" onClick={props.incQty}>
                  <FontAwesomeIcon icon={faPlusCircle} size="lg" color="#32394D"/> 
                </div>
              </div>
            </div>
          </Col>
        </Row>            
    </Card>
  )
}

export default Cart;