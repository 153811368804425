import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils/utility';

const initState = {
  merchantId: null,
  merchantName: null,
  items: [],
  cartQueue: 0,
}

const setMerchant = (state, action) => {
  return updateObject(state, {merchantId: action.merchant.merchantId, merchantName: action.merchant.merchantName});
}

const clearCart = (state) => {
  return updateObject(state, {merchantId: null, merchantName: null, items: [], cartQueue: 0});
}

const addCart = (state, action) => {
  let itemArray = [...state.items];
  let index = itemArray.findIndex(x => x.cartId === action.item.cartId);

  if (index < 0) {
    itemArray = [...state.items, ...[action.item]]
  } else { 
    const updatedCartElements = { 
        ...itemArray[index]
    };
    updatedCartElements.qty += 1;
    updatedCartElements.subtotal += action.item.price;
    itemArray[index] = updateObject(itemArray[index], updatedCartElements);
  }
  return updateObject(state, {items: itemArray});
}

const deleteCart = (state, action) => {
  let itemArray = [...state.items];
  let index = itemArray.findIndex(x => x.cartId === action.item.cartId);
  
  if (index >= 0) {
    const totalQty = itemArray[index].qty;
    if (totalQty > 1) {
      const updatedCartElements = { 
        ...itemArray[index]
      };
      updatedCartElements.qty -= 1;
      updatedCartElements.subtotal -= action.item.price;
      itemArray[index] = updateObject(itemArray[index], updatedCartElements);
    } else {
      itemArray.splice(index, 1)
    }
  }
  return updateObject(state, {items: itemArray});
}

const updateQtyCart = (state, action) => {
  // let itemArray = [...state.items];
  // let index = itemArray.findIndex(x => x.id === action.item.id);

  // if (index < 0) {
  //   if (action.item.qty !== 0) {
  //     console.log(...[action.item])
  //     itemArray = [...state.items, ...[action.item]]
  //   }
  // } else { 
  //   const updatedCartElements = { 
  //       ...itemArray[index]
  //   };

  //   if (action.item.qty !== 0) {
  //     updatedCartElements.qty = action.item.qty;
  //     updatedCartElements.subtotal = action.item.subtotal;
  //     itemArray[index] = updateObject(itemArray[index], updatedCartElements);
  //   } else {
  //     itemArray.splice(index, 1)
  //   }
  // }
  // return updateObject(state, {items: itemArray});

  // let itemArray = [...state.items];
  // let index = itemArray.findIndex(x => x.id === action.item.id);

  // if (index < 0) {
  //   if (action.item.qty !== 0) {
  //     let o = Object.assign({}, action.item);
  //     state.cartQueue = state.cartQueue + 1;
  //     o.cartId = state.cartQueue;
  //     itemArray = [...state.items, ...[o]]
  //   }
  // } else { 
  //   const updatedCartElements = { 
  //       ...itemArray[index]
  //   };

  //   if (action.item.qty !== 0) {
  //     updatedCartElements.qty = action.item.qty;
  //     updatedCartElements.subtotal = action.item.subtotal;
  //     itemArray[index] = updateObject(itemArray[index], updatedCartElements);
  //   } else {
  //     itemArray.splice(index, 1)
  //   }
  // }
  // return updateObject(state, {items: itemArray});

  let itemArray = [...state.items];
  let index = itemArray.findIndex(x => x.id === action.item.id);

  if (index < 0) {
    if (action.item.qty !== 0) {
      let o = Object.assign({}, action.item);
      state.cartQueue = state.cartQueue + 1;
      o.cartId = state.cartQueue;
      itemArray = [...state.items, ...[o]]
    }
  } else { 
    let updatedCartElementIdx = [];
    let updatedAddOns = action.item.listingAddOn;
    itemArray.map( (el, idx) => {
      if(el.id === action.item.id) { updatedCartElementIdx.push(idx); }
      return true;
    })

    let sameAddOn = true;
    let sameIndexes = -1;
    for (let indexes in updatedCartElementIdx) {
      sameAddOn = true;

      const updatedCartElements = { 
        ...itemArray[updatedCartElementIdx[indexes]]
      };  
      let addOns = updatedCartElements.listingAddOn;
    
      if (addOns.length === updatedAddOns.length) {
        let found = false;
        for (let key in updatedAddOns) {
          found = false;
          for (let key2 in addOns) {
            if (updatedAddOns[key].id === addOns[key2].id) {
              found = true;
              break;
            }
          }

          if (found === false) {
            sameAddOn = false;
            break;
          }
        }

        if (found) {
          sameAddOn = true;
          sameIndexes = indexes;
          break;
        }
      } else {
        sameAddOn = false;
      }
    }

    if (action.item.qty !== 0) {
      if (sameAddOn) {
        const updatedCartElements = { 
          ...itemArray[sameIndexes]
        };

        updatedCartElements.qty = action.item.qty;
        updatedCartElements.subtotal = action.item.subtotal;
        itemArray[sameIndexes] = updateObject(itemArray[sameIndexes], updatedCartElements);
      } else {
        let o = Object.assign({}, action.item);
        state.cartQueue = state.cartQueue + 1;
        o.cartId = state.cartQueue;
        itemArray = [...state.items, ...[o]]
      }
    }

    

    // console.log(addOns)
    

    // if (action.item.qty !== 0) {
    //   updatedCartElements.qty = action.item.qty;
    //   updatedCartElements.subtotal = action.item.subtotal;
    //   itemArray[index] = updateObject(itemArray[index], updatedCartElements);
    // } else {
    //   itemArray.splice(index, 1)
    // }
  }

  return updateObject(state, {items: itemArray});
}

const updateCart = (state, action) => {
  return updateObject(state, {items: action.item })
}

const updateAddOnCart = (state, action) => {
  return state
}

const reducer = ( state = initState, action ) => {
  switch ( action.type ) {
    case actionTypes.SET_MERCHANT: return setMerchant(state, action);
    case actionTypes.ADD_CART: return addCart(state, action);
    case actionTypes.CLEAR_CART: return clearCart(state);
    case actionTypes.DEL_CART: return deleteCart(state, action);
    case actionTypes.UPDATE_QTY_CART: return updateQtyCart(state, action);
    case actionTypes.UPDATE_CART: return updateCart(state, action);
    case actionTypes.UPDATE_ADDON_CART: return updateAddOnCart(state, action);
    default:
  }
  return state;
};

export default reducer;