import React, {Component} from 'react';
import api from '../../services/network';
import { listmenuApiPath } from '../../services/networkApi';
import { connect } from 'react-redux';
import Cart from '../../components/cart/cart';
import { Card, Row, Col, Button, Table } from 'react-bootstrap';
import { imageApi } from '../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import imageDefault from '../../assets/img/okgo.png';
import NumberFormat from 'react-number-format';
import RoundedBottom from '../../components/ui/button/roundedButton/roundedButton';
import * as actions from '../../store/actions/index';
import './listingMenu.css';

class listingMenuDetail extends Component {
  state = {
    selectedListing: {},
    listingAddOns: [],
    editable: false,
    listingId: 0,
    cartId: 0,
  }

  async componentDidMount() {
    let listingId = parseInt(this.props.location.state.listing);
    let cartId = parseInt(this.props.location.state.cart);
    let editable = this.props.location.state.edit;
    let listingAddOn = [];
    
    let itemArray = [...this.props.cart];
    let selectedListing = {};

    const specificMenuApi = api(listmenuApiPath);
    const menu = await specificMenuApi.getWithParams(listingId);

    if (menu.length > 0) listingAddOn = JSON.parse(JSON.stringify(menu[0].listingAddOn));

    if (listingId !== 0 && !editable) {
      if (menu.length > 0) { 
        let o = Object.assign({}, menu[0]);
        o.qtyOrder = 0;
        selectedListing = o;
      }
    } else if (listingId !== 0 && editable) {
      let index = itemArray.findIndex(x => x.cartId === cartId);
      let o = Object.assign({}, { ...itemArray[index] });
      o.qtyOrder = o.qty;
      selectedListing = o;

      for (let key in o.listingAddOn) {
        for (let key2 in listingAddOn) {
          if (listingAddOn[key2].id === o.listingAddOn[key].id) {
            listingAddOn[key2].checked = true
          }
        }
      }
      // listingAddOn = [...listingAddOn, ...o.listingAddOn];
      
    }
    
    this.setState({
      selectedListing: selectedListing,
      listingAddOns: listingAddOn,
      listingId: listingId,
      cartId: cartId,
      editable: editable
    })
  }

  backToList = () => {
    this.props.history.goBack();
  }

  handleCheckAddonElement = (event) => {
    let itemData = Object.assign({}, this.state.listingAddOns);
    let listingAddOn = Object.keys(itemData).map((key) =>  itemData[key]);

    listingAddOn.forEach(addOn => {
       if (addOn.id.toString() === event.target.value)
          addOn.checked =  event.target.checked
    })

    this.setState({
      listingAddOns: listingAddOn
    })
  }

  saveDetailListing = () => {
    let itemData = { ...this.state.selectedListing };
    let listingAddOn = [ ...this.state.listingAddOns ];
    let addOn = listingAddOn.filter(items => {
      return items.checked === true;
    });

    const itemCart = {
      id: itemData.id,
      name: itemData.name,
      price: itemData.price,
      oldPrice: itemData.oldPrice,
      qty: itemData.qtyOrder,
      subtotal: itemData.price * itemData.qtyOrder,
      category: itemData.category,
      remark: '',
      merchantId: itemData.merchantId,
      image: itemData.image,
      parentId: itemData.parentId,
      merchantName: itemData.merchantName,
      order: itemData.order,
      supplierId: itemData.supplierId,
      listingAddOn: addOn,
    }

    this.props.onUpdateCart(itemCart);
    this.props.history.goBack();
  }

  addDefaultSrc = (ev) => {
    ev.target.src = imageDefault;
  }

  incrementQty = () => {
    let itemData = { ...this.state.selectedListing }
    itemData.qtyOrder += 1;
    
    this.setState({
      selectedListing: itemData,
    })
  }

  decrementQty = () => {
    let itemData = { ...this.state.selectedListing }
    if (itemData.qtyOrder > 0) {
      itemData.qtyOrder -= 1;
    }

    this.setState({
      selectedListing: itemData,
    })
  }

  render() {
    let listingData = {...this.state.selectedListing};
    let addOns = [...this.state.listingAddOns];

    return (
      <div className="content">
          <div className="information-header">
            <div className="push-bottom-lg">LISTING DETAIL</div>
            <Card className="card-border-bottom col-transparent cart-card">
                <Row noGutters={true}>
                  <Col xs={3} md={3}>
                    <div className="cart-list-img col-center">
                      <img onError={this.addDefaultSrc} src={imageApi+listingData.image} alt=""/>
                    </div>
                  </Col>
                  <Col xs={9} md={9}>
                    <div className="cart-list-body">
                      <div className="information">{listingData.name}</div>
                      <div className="information-small gray">{listingData.merchantName}</div>
                      <div className="information-small gray">
                        <NumberFormat value={listingData.price} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} />
                      </div>
                      <div className="push-up">
                        <div className="float-left push-right" tag="a" onClick={this.decrementQty}>
                          <FontAwesomeIcon icon={faMinusCircle} size="lg" color="#32394D"/> 
                        </div>

                        <div className="push-left float-left push-right information">  {listingData.qtyOrder} </div>
                                        
                        <div className="float-left push-left" tag="a" onClick={this.incrementQty}>
                          <FontAwesomeIcon icon={faPlusCircle} size="lg" color="#32394D"/> 
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>            
            </Card>
            <div className="disable-selected">
              <Table responsive>
                <thead className="text-center"><tr><th><h4><b>ADD ON</b></h4></th></tr></thead>
                <tbody>
                  <tr>
                    <td>
                    <ul>
                      {
                        addOns.map((addOn) => {
                          return (
                            <li key={addOn.id}>
                              <input type="checkbox" onChange={(e) => this.handleCheckAddonElement(e)} checked={addOn.checked} value={addOn.id} /> {addOn.name}  
                              ( <NumberFormat value={addOn.price} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} /> )
                            </li>
                          )
                        })
                      }
                    </ul>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
            
            <div className="app-bottom w-100">
              <div className="d-flex justify-content-center section">
                <RoundedBottom clicked={this.backToList} className="push-right" width="40%" >Cancel</RoundedBottom>
                <RoundedBottom clicked={this.saveDetailListing} width="40%" bgcolor="green">{ this.state.editable ? 'Update' : 'Add Data' }</RoundedBottom>
              </div>
            </div>
          </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
      cart: state.cart.items,
      creator: state.cart.creator,
      cartType: state.cart.cartType,
      isLogin: state.user.isLoggedIn,
      merchantId: state.cart.merchantId,
      token: state.user.token,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdateCart: (item) => dispatch(actions.updateQtyCart(item)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(listingMenuDetail);
