import React, {Component} from 'react';
import ReactPlayer from 'react-player';
import Slider from 'react-slick';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import RoundedBottom from '../../components/ui/button/roundedButton/roundedButton';
import api from '../../services/network';
import { listMenuVideoApiPath } from '../../services/networkApi';
import './homeScreen.css';
import FloatingButton from '../../components/ui/button/floatingButton/floatingButton';
import Modal from '../../components/ui/modal/modal'; 
import {
  Button,   
  Row,
  Col,
  Table
} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusSquare, faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { videoApi } from '../../config';

class HomeScreen extends Component {
  state = {
    listing: [],
    selectedItem: {},
    activeIndex: 0,
    cartable: false,
  }

  async componentDidMount() {
    if (this.props.isLoggedIn !== true || this.props.parentId === null) {
      this.props.history.push('/login');
    } 

    if (this.props.creator === 'MERCHANT') {
      this.props.onSetMerchant(this.props.parentId, this.props.fullName);
      const listingApi = api(listMenuVideoApiPath)
      const payload = {merchantId:  this.props.parentId}
      const listingMenu = await listingApi.post(payload);

      let result = listingMenu.map( (el, index) => {
        let o = Object.assign({}, el);
        o.qtyOrders = 0;
        o.index = index;
        return o;
      });

      this.setState({
        listing: result
      })
    } else {
      this.props.history.push('/home');
    }
  }

  getItemInformation = (index) => {
    let data = [
      ...this.state.listing,
    ]

    const itemList = data.filter(items => {
      return items.index === this.state.activeIndex;
    });

    if (itemList.length > 0) {
      return itemList[0].name + ' ( Rp.' + itemList[0].price + ' )' ;
    }

    return ''
  }

  showAllMenu = () => {
    this.props.history.push('/listing-menu');
  }

  gotoCart = () => {  
    this.props.history.push('/listing-cart');
  }

  cartContinuedHandler = () => {
    let itemData = {};
    let data = [
      ...this.state.listing,
    ]

    const itemList = data.filter(items => {
      return items.index === this.state.activeIndex;
    });

    if (itemList.length > 0) {
      itemData = Object.assign({}, itemList[0]);
    }
    
    this.setState({
      cartable: true,
      selectedItem: itemData,
    });
  }

  cartCanceledHandler = () => {
    this.setState({
      cartable: false,
    });
  }

  incrementQty = (item) => {
    item.qtyOrders += 1;
    this.setState({
      selectedItem: item
    })
  }

  decrementQty = (item) => {
    if (item.qtyOrders === 0) { return; }

    item.qtyOrders -= 1;
    this.setState({
      selectedItem: item
    })
  }

  updateQty = (item) => {
    let updatedListing = [
      ...this.state.listing
    ]

    const itemList = updatedListing.filter(items => {
      return items.id === item.id && items.category === item.category;
    });

    if (itemList.length <= 0) {return;}
    
    itemList[0].qtyOrders = item.qtyOrders;

    const itemCart = {
      id: itemList[0].id,
      name: itemList[0].name,
      price: itemList[0].price,
      oldPrice: itemList[0].oldPrice,
      qty: item.qtyOrders,
      subtotal: itemList[0].price * item.qtyOrders,
      category: itemList[0].category,
      remark: '',
      merchantId: itemList[0].merchantId,
      image: itemList[0].image,
      parentId: itemList[0].parentId,
      merchantName: itemList[0].merchantName,
      order: 1,
      supplierId: itemList[0].supplierId,
    }

    this.setState({
      cartable: false,
      selectedItem: {},
      ...this.state.listing,
      ...updatedListing
    }, () => this.props.onUpdateCart(itemCart)); 
  }

  render() {
    let floatButton = null;
    let cartModal = null;
    let orderButton = null;
    let item = this.state.selectedItem;

    let settings = {
      dots: true,
      infinite: true,
      arrows: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      centerMode: true,
      variableWidth: true,
      afterChange: (obj) => {
        this.setState({activeIndex: obj})
      }
    };

    orderButton = this.state.listing.length > 0 ? (<div className="d-flex justify-content-center">
    <RoundedBottom clicked={() => this.cartContinuedHandler()} width="70%" bgcolor="black" color="white">ORDER</RoundedBottom>
    </div>) : null;

    cartModal = Object.keys(item).length > 0 ? (
      <Modal show={this.state.cartable} modalClosed={this.cartCanceledHandler}>
        <div className="text-center disable-selected">
          <Table responsive>
            <thead>
              <tr>
                <th><h4><b>Q T Y</b></h4></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Row>
                    <Col>
                      <div className="col-center" tag="a" onClick={() => this.decrementQty(item)}>
                        <FontAwesomeIcon icon={faMinusSquare} size="2x" color="black"/> 
                      </div>
                    </Col>
                    <Col>
                      <div className="col-center"> {item.qtyOrders} </div>
                    </Col>
                    <Col>
                      <div className="col-center" tag="a" onClick={() => this.incrementQty(item)}>
                        <FontAwesomeIcon icon={faPlusSquare} size="2x" color="black"/> 
                      </div>
                    </Col>
                  </Row>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="row justify-content-between push-up-lg">
                    <div className="col-4">
                      <Button outline color="danger" className='w-100' onClick={this.cartCanceledHandler}> CANCEL </Button>
                    </div>
                    <div className="col-4">
                      <Button outline color="success" className='w-100' onClick={() => this.updateQty(item)}> OK </Button>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Modal>
    ) : null;

    let textDescription = this.getItemInformation();

    let listMenu = this.state.listing.map( el => (
      <div className="w-100">
        <ReactPlayer 
          url={[
            {src: videoApi + el.video, type: 'video/mp4'}
          ]}
          config={{
            file: {attributes: {autoPlay: true, controls: false}}
          }}
          muted={true}
          autoPlay={true}
          playing={true}
          loop={true}
          controls={false}
          width="100%" height="100%"
          ></ReactPlayer>
      </div>
    ))

    floatButton = this.props.cart.length > 0 ? <FloatingButton clicked={this.gotoCart} cartCount={this.props.cart.length}></FloatingButton> : null;

    return (
      <React.Fragment>
        {floatButton}
        {cartModal}
        <div tag='a' onClick={() => {this.showAllMenu()}} className="button-all-menu"><u>SHOW ALL MENU</u></div>
        <div className="video">
          <Slider {...settings}>
            {listMenu}
          </Slider>
          <div className="video-description text-center">{textDescription}</div>
          {/* <div className="d-flex justify-content-center">
            <RoundedBottom clicked={() => this.cartContinuedHandler()} width="70%" bgcolor="black" color="white">ORDER</RoundedBottom>
          </div> */}

          {orderButton}
        </div>
      </React.Fragment>
      
    )
  }
}

const mapStateToProps = state => {
  return {
      isLoggedIn: state.user.isLoggedIn,
      parentId: state.user.parentId,
      fullName: state.user.fullName,
      creator: state.user.creator,
      cart: state.cart.items,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSetMerchant: (merchant, name) => dispatch(actions.setMerchant(merchant, name)),
    onStoreCart: (item) => dispatch(actions.addCart(item)),
    onRemoveCart: (item) => dispatch(actions.deleteCart(item)),
    onUpdateCart: (item) => dispatch(actions.updateQtyCart(item)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps )( HomeScreen );









