// import React, {Component} from 'react';
// import ReactPlayer from 'react-player';
// import { connect } from 'react-redux';
// import * as actions from '../../store/actions/index';
// import RoundedBottom from '../../components/ui/button/roundedButton/roundedButton';
// import api from '../../services/network';
// import { listMenuVideoApiPath } from '../../services/networkApi';
// import './homeScreen.css';
// import './homeScreen2.css';
// import FloatingButton from '../../components/ui/button/floatingButton/floatingButton';
// import Modal from '../../components/ui/modal/modal'; 
// import {
//   Button,   
//   Row,
//   Col,
//   Table
// } from "reactstrap";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faMinusSquare, faPlusSquare } from '@fortawesome/free-solid-svg-icons';
// import { Carousel } from 'react-bootstrap';
// import { videoApi } from '../../config';

// class HomeScreen2 extends Component {
//   state = {
//     listing: [],
//     selectedItem: {},
//     activeIndex: 0,
//     cartable: false,
//   }

//   async componentDidMount() {
//     if (this.props.isLoggedIn !== true || this.props.parentId === null) {
//       this.props.history.push('/login');
//     } else {
//       if (this.props.creator === 'MERCHANT') {
//         this.props.onSetMerchant(this.props.parentId, this.props.fullName);
//         const listingApi = api(listMenuVideoApiPath)
//         const payload = {merchantId:  this.props.parentId}
//         const listingMenu = await listingApi.post(payload);

//         let result = listingMenu.map( (el, index) => {
//           let o = Object.assign({}, el);
//           o.qtyOrders = 0;
//           o.index = index;
//           return o;
//         });

//         this.setState({
//           listing: result
//         })
//       } else {
//         this.props.history.push('/home');
//       }
//     }
//   }

//   getItemInformation = (index) => {
//     let data = [
//       ...this.state.listing,
//     ]

//     const itemList = data.filter(items => {
//       return items.index === this.state.activeIndex;
//     });

//     if (itemList.length > 0) {
//       // return itemList[0].name + ' ( Rp.' + itemList[0].price + ' )' ;
//       return itemList[0];
//     }

//     return {};
//   }

//   showAllMenu = () => {
//     this.props.history.push('/listing-menu');
//   }

//   handleSelectVideo = (selectedIndex) => {
//     this.setState({activeIndex: selectedIndex})
//   }

//   gotoCart = () => {  
//     this.props.history.push('/listing-cart');
//   }

//   cartContinuedHandler = () => {
//     let itemData = {};
//     let data = [
//       ...this.state.listing,
//     ]

//     const itemList = data.filter(items => {
//       return items.index === this.state.activeIndex;
//     });

//     if (itemList.length > 0) {
//       itemData = Object.assign({}, itemList[0]);
//     }
    
//     this.setState({
//       cartable: true,
//       selectedItem: itemData,
//     });
//   }

//   cartCanceledHandler = () => {
//     this.setState({
//       cartable: false,
//     });
//   }

//   incrementQty = (item) => {
//     item.qtyOrders += 1;
//     this.setState({
//       selectedItem: item
//     })
//   }

//   decrementQty = (item) => {
//     if (item.qtyOrders === 0) { return; }

//     item.qtyOrders -= 1;
//     this.setState({
//       selectedItem: item
//     })
//   }

//   updateQty = (item) => {
//     let updatedListing = [
//       ...this.state.listing
//     ]

//     const itemList = updatedListing.filter(items => {
//       return items.id === item.id && items.category === item.category;
//     });

//     if (itemList.length <= 0) {return;}
    
//     itemList[0].qtyOrders = item.qtyOrders;

//     const itemCart = {
//       id: itemList[0].id,
//       name: itemList[0].name,
//       price: itemList[0].price,
//       oldPrice: itemList[0].oldPrice,
//       qty: item.qtyOrders,
//       subtotal: itemList[0].price * item.qtyOrders,
//       category: itemList[0].category,
//       remark: '',
//       merchantId: itemList[0].merchantId,
//       image: itemList[0].image,
//       parentId: itemList[0].parentId,
//       merchantName: itemList[0].merchantName,
//       order: 1,
//       supplierId: itemList[0].supplierId,
//     }

//     this.setState({
//       cartable: false,
//       selectedItem: {},
//       ...this.state.listing,
//       ...updatedListing
//     }, () => this.props.onUpdateCart(itemCart)); 
//   }

//   render() {
//     let floatButton = null;
//     let cartModal = null;
//     let orderButton = null;
//     let item = this.state.selectedItem;

//     orderButton = this.state.listing.length > 0 ? (<div className="push-up-md text-vdescription-header d-flex justify-content-center">
//     <RoundedBottom clicked={() => this.cartContinuedHandler()} width="65%" bgcolor="#FE616B" color="white">ORDER</RoundedBottom>
//     </div>) : null;

//     cartModal = Object.keys(item).length > 0 ? (
//       <Modal show={this.state.cartable} modalClosed={this.cartCanceledHandler}>
//         <div className="text-center disable-selected">
//           <Table responsive>
//             <thead>
//               <tr>
//                 <th><h4><b>Q T Y</b></h4></th>
//               </tr>
//             </thead>
//             <tbody>
//               <tr>
//                 <td>
//                   <Row>
//                     <Col>
//                       <div className="col-center" tag="a" onClick={() => this.decrementQty(item)}>
//                         <FontAwesomeIcon icon={faMinusSquare} size="2x" color="black"/> 
//                       </div>
//                     </Col>
//                     <Col>
//                       <div className="col-center"> {item.qtyOrders} </div>
//                     </Col>
//                     <Col>
//                       <div className="col-center" tag="a" onClick={() => this.incrementQty(item)}>
//                         <FontAwesomeIcon icon={faPlusSquare} size="2x" color="black"/> 
//                       </div>
//                     </Col>
//                   </Row>
//                 </td>
//               </tr>
//               <tr>
//                 <td>
//                   <div className="row justify-content-between push-up-lg">
//                     <div className="col-4">
//                       <Button outline color="danger" className='w-100' onClick={this.cartCanceledHandler}> CANCEL </Button>
//                     </div>
//                     <div className="col-4">
//                       <Button outline color="success" className='w-100' onClick={() => this.updateQty(item)}> OK </Button>
//                     </div>
//                   </div>
//                 </td>
//               </tr>
//             </tbody>
//           </Table>
//         </div>
//       </Modal>
//     ) : null;

//     let videoData = this.getItemInformation();

//     let listMenu = this.state.listing.map( (el, idx) => (
//       <Carousel.Item key={idx}>
//         <div className="d-flex align-items-center justify-content-center video-h">
//           <ReactPlayer 
//             url={[
//               {src: videoApi + el.video, type: 'video/mp4'}
//             ]}
//             config={{
//               file: {attributes: {autoPlay: true, controls: false}}
//             }}
//             muted={true}
//             autoPlay={true}
//             playing={true}
//             loop={true}
//             controls={false}
//             width="100%" height="100%"
//             ></ReactPlayer>
//         </div>
//       </Carousel.Item>
//     ))

//     floatButton = this.props.cart.length > 0 ? <FloatingButton clicked={this.gotoCart} cartCount={this.props.cart.length}></FloatingButton> : null;

//     return (
//       <React.Fragment>
//         <div className="video-layout">
//           {floatButton}
//           {cartModal}
//           {/* <div tag='a' onClick={() => {this.showAllMenu()}} className="button-all-menu"><u>SHOW ALL MENU</u></div> */}
//           <div className="video-top-banner">
//             <div className="row justify-content-center align-items-center">
//               <div className="col">  
//                 MENU ORDER
//               </div>
//             </div>
//             <div className="d-flex flex-row-reverse">
//               <div className="p-2 button-all-menu">
//                 <div tag='a' onClick={this.showAllMenu} className="text-vdescription-small"><u>SHOW ALL MENU</u></div> 
//               </div>
//             </div>
//           </div>
          
//           <Carousel activeIndex={this.state.activeIndex} onSelect={(idx) => {this.handleSelectVideo(idx)}}>
//             {listMenu}    
//           </Carousel>

//           <div className="video-description">
//             <div className="text-vdescription-header d-flex justify-content-center">
//               {videoData.name}
//             </div>
//             <div className="text-vdescription-small d-flex justify-content-center">
//               {videoData.detail}
//             </div>
//             <div className="text-vdescription-small d-flex justify-content-center">
//               {videoData.price}
//             </div>
//             {orderButton}
//           </div>
//         </div>
        
//       </React.Fragment>
      
//     )
//   }
// }

// const mapStateToProps = state => {
//   return {
//       isLoggedIn: state.user.isLoggedIn,
//       parentId: state.user.parentId,
//       fullName: state.user.fullName,
//       creator: state.user.creator,
//       cart: state.cart.items,
//   };
// };

// const mapDispatchToProps = dispatch => {
//   return {
//     onSetMerchant: (merchant, name) => dispatch(actions.setMerchant(merchant, name)),
//     onStoreCart: (item) => dispatch(actions.addCart(item)),
//     onRemoveCart: (item) => dispatch(actions.deleteCart(item)),
//     onUpdateCart: (item) => dispatch(actions.updateQtyCart(item)),
//   };
// };

// export default connect(mapStateToProps, mapDispatchToProps )( HomeScreen2 );


import React, {Component} from 'react';
import ReactPlayer from 'react-player';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import RoundedBottom from '../../components/ui/button/roundedButton/roundedButton';
import api from '../../services/network';
import { listMenuVideoApiPath } from '../../services/networkApi';
import './homeScreen.css';
import './homeScreen2.css';
import FloatingButton from '../../components/ui/button/floatingButton/floatingButton';
import Modal from '../../components/ui/modal/modal'; 
import {
  Button,   
  Row,
  Col,
  Table
} from "reactstrap";
import { Carousel } from 'react-bootstrap';
import { videoApi } from '../../config';
import { summaryArray } from '../../utils/utility';
import {
  Accordion,
  Button as BootsButton,
} from 'react-bootstrap';
import NumberFormat from 'react-number-format'

class HomeScreen2 extends Component {
  state = {
    listing: [],
    selectedItem: [],
    selectedAddon: [],
    activeIndex: 0,
    cartable: false,
  }

  async componentDidMount() {
    if (this.props.isLoggedIn !== true || this.props.parentId === null) {
      this.props.history.push('/login');
    } else {
      if (this.props.creator === 'MERCHANT') {
        this.props.onSetMerchant(this.props.parentId, this.props.fullName);
        const listingApi = api(listMenuVideoApiPath)
        const payload = {merchantId:  this.props.parentId}
        const listingMenu = await listingApi.post(payload);

        let result = listingMenu.map( (el, index) => {
          let cartItem = this.props.cart.filter(function(item) {
            return item.id === el.id;
          });
          let o = Object.assign({}, el);
          o.qtyOrders = summaryArray(cartItem, 'qty')
          o.index = index;
          return o;
        });

        this.setState({
          listing: result
        })
      } else {
        this.props.history.push('/home');
      }
    }
  }

  getItemInformation = (index) => {
    let data = [
      ...this.state.listing,
    ]

    const itemList = data.filter(items => {
      return items.index === this.state.activeIndex;
    });

    if (itemList.length > 0) {
      return itemList[0];
    }

    return {};
  }

  showAllMenu = () => {
    this.props.history.push('/listing-menu');
  }

  handleSelectVideo = (selectedIndex) => {
    this.setState({activeIndex: selectedIndex})
  }

  gotoCart = () => {  
    this.props.history.push('/listing-cart');
  }

  cartContinuedHandler = () => {
    let itemData = {};
    let data = [
      ...this.state.listing,
    ]

    const itemList = data.filter(items => {
      return items.index === this.state.activeIndex;
    });

    if (itemList.length > 0) {
      itemData = Object.assign({}, itemList[0]);
    }

    let carts = [ ...this.props.cart ];

    const cart = carts.filter(items => {
      return items.id === itemData.id;
    });

    if (cart.length === 0) {
      this.props.history.push({
        pathname: '/listing-menu/detail',
        state: {
          listing: itemData.id,
          edit: false,
          cart: 0
        }
      });
    } else {
      this.setState({
        cartable: true,
        selectedItem: cart,
      });
    }
  }

  cartCanceledHandler = () => {
    this.setState({
      cartable: false,
    });
  }

  gotoDetailCart = (listingId, cartId, isEdit) => {
    if (isEdit) {
      this.props.history.push({
        pathname: '/listing-menu/detail',
        state: {
          listing: listingId,
          edit: true,
          cart: cartId
        }
      });
    } else {
      this.props.history.push({
        pathname: '/listing-menu/detail',
        state: {
          listing: listingId,
          edit: false,
          cart: 0
        }
      });
    }
  }

  render() {
    let floatButton = null;
    let orderButton = null;
    let item = this.state.selectedItem;
    let cartModal = null;
    let cartList = null;

    cartList = item.map( (el, index) => {
      let listAddOn = null;
      let addOn = null;
      if (typeof el.listingAddOn !== 'undefined' && el.listingAddOn.length > 0) {
        listAddOn =el.listingAddOn.map ((elAdd, indexAdd) => {
          return (
            <li key={indexAdd}> {elAdd.name} </li>
          )
        })

        addOn = (
          <Accordion>
            <Accordion.Toggle as={BootsButton} variant="link" eventKey="0" className="toggle-accordion">
              View AddOn
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <ul>
                {listAddOn}
              </ul>
            </Accordion.Collapse>
          </Accordion> 
        )
      }

      return (
        <Row key={index}>
          <Col>
            {el.name}
          </Col>
          <Col>
            {el.qty}
          </Col>
          <Col> {addOn} </Col>
          <Col>
            <div tag="a" onClick={() => this.gotoDetailCart(el.id, el.cartId, true)} className="float-right"> Edit </div>
          </Col>
        </Row>
      )
    })

    cartModal = Object.keys(item).length > 0 ? (
      <Modal show={this.state.cartable} modalClosed={this.cartCanceledHandler}>
        <div className="disable-selected">
          <Table responsive>
            <thead className="text-center"><tr><th><h4><b>UPDATE ITEM DETAIL</b></h4></th></tr></thead>
            <tbody>
              <tr>
                <td>
                  {cartList}
                </td>
              </tr>
              <tr>
                <td>
                  <div className="row justify-content-between push-up-lg">
                    <div className="col-4">
                      <Button outline color="success" className='w-100' onClick={() => this.gotoDetailCart(item[0].id, 0, false)}> ADD NEW </Button>
                    </div>
                    <div className="col-4">
                      <Button outline color="danger" className='w-100' onClick={this.cartCanceledHandler}> CANCEL </Button>
                      {/* <Button outline color="success" className='w-100' onClick={() => this.updateQty(item)}> OK </Button> */}
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Modal>
    ) : null;

    orderButton = this.state.listing.length > 0 ? (<div className="push-up text-vdescription-header d-flex justify-content-center">
    <RoundedBottom height="5vh" clicked={() => this.cartContinuedHandler()} width="65%" bgcolor="#FE616B" color="white">ORDER</RoundedBottom>
    </div>) : null;

    let videoData = this.getItemInformation();

    let listMenu = this.state.listing.map( (el, idx) => (
      <Carousel.Item key={idx}>
        <div className="d-flex align-items-center justify-content-center video-h">
          <ReactPlayer 
            url={[
              {src: videoApi + el.video, type: 'video/mp4'}
            ]}
            config={{
              file: {attributes: {autoPlay: true, controls: false}}
            }}
            muted={true}
            autoPlay={true}
            playing={true}
            loop={true}
            controls={false}
            width="100%" height="100%"
            ></ReactPlayer>
        </div>
      </Carousel.Item>
    ))

    floatButton = this.props.cart.length > 0 ? <FloatingButton clicked={this.gotoCart} cartCount={this.props.cart.length}></FloatingButton> : null;

    return (
      <React.Fragment>
        <div className="video-layout">
          {floatButton}
          {cartModal}
          <div className="video-top-banner">
            <div className="row justify-content-center align-items-center">
              <div className="col">  
                MENU ORDER
              </div>
            </div>
            <div className="d-flex flex-row-reverse">
              <div className="p-2 button-all-menu">
                <div tag='a' onClick={this.showAllMenu} className="text-vdescription-small"><u>SHOW ALL MENU</u></div> 
              </div>
            </div>
          </div>
          
          <Carousel activeIndex={this.state.activeIndex} onSelect={(idx) => {this.handleSelectVideo(idx)}}>
            {listMenu}    
          </Carousel>

          <div className="video-description">
            <div className="text-vdescription-header d-flex justify-content-center">
              {videoData.name}
            </div>
            <div className="text-vdescription-small d-flex justify-content-center">
              {videoData.detail}
            </div>
            <div className="text-vdescription-small d-flex justify-content-center">
              <NumberFormat value={videoData.price} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} />
            </div>
            {orderButton}
          </div>
        </div>
        
      </React.Fragment>
      
    )
  }
}

const mapStateToProps = state => {
  return {
      isLoggedIn: state.user.isLoggedIn,
      parentId: state.user.parentId,
      fullName: state.user.fullName,
      creator: state.user.creator,
      cart: state.cart.items,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSetMerchant: (merchant, name) => dispatch(actions.setMerchant(merchant, name)),
    onStoreCart: (item) => dispatch(actions.addCart(item)),
    onRemoveCart: (item) => dispatch(actions.deleteCart(item)),
    onUpdateCart: (item) => dispatch(actions.updateQtyCart(item)),
    onUpdateAddOnCart: (item) => dispatch(actions.updateAddOnCart(item)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps )( HomeScreen2 );

