import * as actionTypes from './actionTypes';
import { loginApiPath, merchantCheckApiPath } from '../../services/networkApi';
import api from '../../services/network';
import showNotifications from '../../components/Toast';

export const authRemove = () => {
  return {
    type: actionTypes.REMOVE_AUTHENTICATION
  }
}

export const authStart = () => {
  return {
      type: actionTypes.AUTHENTICATION_START
  };
};

export const authSuccess = (user) => {
  return {
      type: actionTypes.SET_AUTHENTICATION,
      userInfo: user
  };
};

export const authFail = (error) => {
  return {
      type: actionTypes.AUTHENTICATION_FAIL,
      error: error
  };
};

export const auth = (email, password) => {
  return async dispatch => {
    dispatch(authStart());
    const authData = {
      email: email,
	    password: password
    }

    const loginApi = api(loginApiPath)
    const loginRes = await loginApi.post(authData);
    
    if (loginRes.errors[0].message === '') {
      const data = {
        merchantId: loginRes.merchantId,
      }
      const merchantTypeApi = api(merchantCheckApiPath);
      const merchantType = await merchantTypeApi.post(data);

      if (merchantType) {
        const user = {
          userId: loginRes.id,
          fullName: loginRes.fullname,
          parentId: loginRes.merchantId,
          token: loginRes.token,
          creator: merchantType.creator,
        }

        showNotifications('Login successfully', 'success', 'Login')
        dispatch(authSuccess(user));  
      }
    } else {
      showNotifications("Failed to login '" + loginRes.errors[0].message + "'", 'error', 'Login')
      // dispatch(authFail("Failed to login '" + loginRes.errors[0].message + "'" ));
    }
  }
}